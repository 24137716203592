import React, { useState,useEffect } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import { SocialIcon } from 'react-social-icons';
import axios from 'axios';
import { customShowLoadingLayer,customHideLoadingLayer,showWarningLayer } from '../../utils/utils';
import * as actionProjectCreators from '../../redux/project/project.actions';
import * as actionCreators from '../../redux/login/login.actions';
import * as actionDefinitionCreators from '../../redux/definitions/definitions.actions';

import CheckBox from '../../components/checkbox/checkbox.component';
import NavBarSub from '../../components/navbarsub/navbarsub.component';
import ChatPopup from '../../components/chatpopup/chatpopup.component';

import 'reactjs-popup/dist/index.css';
import './expert.page.styles.scss';

const API_URL = process.env.REACT_APP_API_URL; 
const MEDYA_GROUP = process.env.REACT_APP_MEDYA_GROUP;


const ExpertPage = () => {
  const { eid, ename} = useParams();
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const history = useHistory();
  const url = "?page="+xprops.project.searchQueryParams.page+"&keyword="+xprops.project.searchQueryParams.keyword+"&profession="+xprops.project.searchQueryParams.profession;
  const currentExpertUrl = window.location.pathname;
  
  const setExpertDetails = (expertDetails) => dispatch(actionProjectCreators.setExpertDetails(expertDetails));

  const onWarning = (payload) => {
    dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload});
  }

  useEffect(()=>{
    window.scrollTo(0,0);
    customShowLoadingLayer();
    dispatch(actionCreators.setExpertUrlBeforeLogin(currentExpertUrl));       
    getExpertDetails();
  },[]);

  const getExpertDetails = async () => {
    customShowLoadingLayer();   
    if (eid === null || eid === ""){
      customHideLoadingLayer();
      history.push("/home");
    }
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    fmData.append("eid", eid);    
    
    let warnmessage = "";
    const res = await axios.post(
      API_URL+"getexpertdetails",
      fmData,
      config
    );
    
    if (res.data.type === "done"){
      setExpertDetails(res.data.data);
      //dispatch(setExpertDetails)
      customHideLoadingLayer();   
    }else{
      if (res.data.message === "DP_Empty"){
        warnmessage = "Lütfen bir uzman seçin.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer(); 
      customHideLoadingLayer();   
    }    
  }

  const prepareSendMessage = () => {
    if (xprops.login.isLoggedIn === true){
      if (xprops.login.User.GroupID === MEDYA_GROUP){
        dispatch(actionDefinitionCreators.setFormChatStatus(true));
        //Chat Window
      }else{
        // Login ise Medya Hesabi ile giris yapiniz.
        dispatch(actionCreators.setChatMessageAfterLogin(true));
        dispatch(actionCreators.setFormLoginStatus(true));
      }
    }else{
      // Degiskenleri ayarla
      dispatch(actionCreators.setChatMessageAfterLogin(true));
      dispatch(actionCreators.setFormLoginStatus(true));        
    }
  }

  return(
    <React.Fragment>
      <div className="expertSectionWrapper">
        <NavBarSub pageType="expert" returnPage={url}  />
        

        {
          xprops.project.expertDetails !== null ?
          <React.Fragment>
          <div className="expertWrapper">
            <div className="expertList">
              <div className="expertListSticky">
                <img className="expertProfileImg" src={`https://www.sensizolmazsessizolmaz.org/uploads/normal_profile_${xprops.project.expertDetails.USERID}.jpg`} alt={ename} onError={(e)=>{e.target.onerror = null; e.target.src="/assets/images/default-woman.jpg"}} />
                <ul className="ulexpertList">
                  <li className="expertFilterTitle">
                    <h4 className="lastExpertName">{ename}</h4>
                    <p className="lastExpertTitle">{xprops.project.expertDetails.YASADIGIULKE} / {xprops.project.expertDetails.YASADIGISEHIR}</p>
                  </li>
                  <li className="expertFilterTitle">
                    <h4 className="lastExpertContactTitle">İletişim Bilgileri</h4>
                    <p className="lastExpertContactInfo">
                      {
                        xprops.project.expertDetails.ILETISIMTERCIHEPOSTAGOSTER === "1" &&
                         <span>{xprops.project.expertDetails.PROFILDEEPOSTA}</span>
                      }
                      {
                        xprops.project.expertDetails.ILETISIMTERCIHTELEFONGOSTER === "1" &&
                         <span>{xprops.project.expertDetails.PROFILDETELEFON}</span>
                      }
                      
                    </p>
                    {
                      
                      xprops.project.expertDetails.GIZLIPLATFORMLAR.match('whatsapp') && 
                        <button className="btnWeb lastExpertDetailBtn contactButtons" onClick={()=>prepareSendMessage()}>Mesaj Gönder</button>  
                        
                    }
                    
                    {
                      /*
                      expertDetails.GIZLIPLATFORMLAR.match('arama') && 
                        <button className="btnWeb lastExpertDetailBtn contactButtons">Arama Yap</button>

                        */
                    }                    
                    
                  </li>
                </ul>
              </div>
            </div>
            <div className="expertContent">
              <h2 className="webSectionTitle">Uzman Bilgileri</h2>
              <div>
                <ul className="expertIntroUl">
                  <li>
                    <div>
                      <strong>Çalıştığı Kurum : </strong>
                    </div>
                    <div>
                      {xprops.project.expertDetails.BAGIMSIZMI === "" ? "Belirtilmemiş" : xprops.project.expertDetails.BAGIMSIZMI === "1" ? "Bağımsız çalışıyor" : xprops.project.expertDetails.CALISTIGIKURUM === "" ? "Belirtilmemiş" : <span>{xprops.project.expertDetails.CALISTIGIKURUM} </span>} {xprops.project.expertDetails.WEBSITE !== "" && <span>&nbsp; /&nbsp; {xprops.project.expertDetails.WEBSITE} </span>}
                    </div>
                  </li>
                  <li>
                    <div>
                      <strong>Medya Deneyimi : </strong>
                    </div>
                     <div>
                     {xprops.project.expertDetails.MEDYADENEYIMIVARMI === "" ? "Belirtilmemiş" : xprops.project.expertDetails.MEDYADENEYIMIVARMI === "1" ? <CheckBox value="var" defaultChecked={true} checked={true} readonly>Var</CheckBox> : <CheckBox value="yok" defaultChecked={false} checked={false} readonly>Yok</CheckBox>}
                     </div>               
                  </li>
                  <li>
                    <div className="">
                      <strong>Konferans Deneyimi : </strong>
                    </div>
                    <div>
                      {xprops.project.expertDetails.KONFERANSDENEYIMIVARMI === "" ? "Belirtilmemiş" : xprops.project.expertDetails.KONFERANSDENEYIMIVARMI === "1" ? <CheckBox value="var" defaultChecked={true} checked={true} readonly>Var</CheckBox> : <CheckBox value="yok" defaultChecked={false} checked={false} readonly>Yok</CheckBox>}
                    </div>
                  </li>
                </ul>
              </div>
              <h2 className="expertSectionTitle">Kısa Özgeçmiş</h2>
              <p className="expertContentP">{xprops.project.expertDetails.KISAOZGECMIS}</p>
              <h2 className="expertSectionTitle">Uzmanlık Alanları</h2>
              <div className="expertContentP">
                <ul>
                {
                  xprops.project.expertDetails.UZMANLIKALANLARI.split("|").map((value,index)=>{
                    return(
                      <li key={index}>{value}</li>
                    )
                  })
                }
                </ul>
              </div>              
              <h2 className="expertSectionTitle">Uzman Olduğu Ülkeler</h2>
              <div className="expertContentP">
                <ul>
                {
                  xprops.project.expertDetails.UZMANOLUNANULKELER.split("|").map((value,index)=>{
                    return(
                      <li key={index}>{value}</li>
                    )
                  })
                }
                </ul>
              </div>                            
              <h2 className="expertSectionTitle">Görüş Vermek İstediği Konular</h2>
              <div className="expertContentP">
                <ul>
                {
                  xprops.project.expertDetails.GORUSVERILECEKKONULAR.split("|").map((value,index)=>{
                    return(
                      <li key={index}>{value}</li>
                    )
                  })
                }
                </ul>
              </div>  
              <h2 className="expertSectionTitle">Yayın ve Referanslar</h2>
              <div className="expertContentP">
                <ul>
                {
                  xprops.project.expertDetails.YAYINVEREFERANSLAR !== ""  ?
                  xprops.project.expertDetails.YAYINVEREFERANSLAR.split("|").map((value,index)=>{
                    return(
                      <li key={index}>{value}</li>
                    )
                  }) : <span>Belirtilmemiş</span>
                }
                </ul>
              </div>   
              <h2 className="expertSectionTitle">Sosyal Medya Hesapları</h2>
              <div className="expertContentP">
                <ul className="expertSocialMedyaUl">
                {
                  xprops.project.expertDetails.SOSYALMEDYAHESAPLARI !== ""  ?
                  xprops.project.expertDetails.SOSYALMEDYAHESAPLARI.split("|").map((value,index)=>{
                    return(
                      <li key={index}>
                        <div className="socialLine"><SocialIcon className="socialMediaIcon" target="_blank"  style={{ height: 30, width: 30 }} url={value} /> <a href={value} target="_blank" rel="noreferrer"><span className="socialMedyaInfo">{value}</span></a></div>                      
                      </li>
                      
                    )
                  }) : <span>Belirtilmemiş</span>
                }
                </ul>
              </div>                                          
              <div className="clean"></div>
            </div>
          </div>          
          </React.Fragment>
          :
          <React.Fragment>
            <div className="expertLoading">
              <p>Yükleniyor...</p>
            </div>
          </React.Fragment> 
        }

        </div>

        {
          //  xprops.login.isLoggedIn === true && xprops.login.User.GroupID === MEDYA_GROUP &&
          //   xprops.project.expertDetails !== null && xprops.project.expertDetails !== undefined &&
          //   <ChatPopup fullname={ename} />
           //xprops.login.isLoggedIn === true && 
            //<ChatPopup fullname={ename} />
        }
     

    </React.Fragment>
  );
};

export default ExpertPage;