import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import ImgCrop from 'antd-img-crop';
import { Upload, Progress } from 'antd';
import { showWarningLayer } from '../../utils/utils';
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';

import axios from "axios";
import 'antd/dist/antd.css';
//import ImageUploader from 'react-images-upload';

//import uploadButton from '../../assets/img/upload.svg';
//import removeButton from '../../assets/img/delete.svg';

import './step1.styles.scss';

class Step1 extends React.Component{

  constructor(props){
    super(props);
    this.state = {
       defaultFileList: (this.props.step1.profilePic === "" || this.props.step1.profilePic === undefined || this.props.step1.profilePic === null) ? [] : [{uid: '-1',name:this.props.step1.profilePic, status: 'done', url:`https://www.sensizolmazsessizolmaz.org/img/${this.props.step1.profilePic}` }],
       progress:0,
       profilePic:"",
       ext:"",
       fullname:""
    };
    this.uploadButton = React.createRef();
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleFullName = this.handleFullName.bind(this);
    this.handleEmailAddress = this.handleEmailAddress.bind(this);
    this.onPreview = this.onPreview.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.props.setStep1({
      ...this.props.step1,
      fullnameError: false
    });    
  }

  handleOnChange({ file, fileList, event }){
    this.setState({
      ...this.state,
      defaultFileList: fileList
    })
  };

  handleFullName(event){
    this.props.setStep1({
      ...this.props.step1,
      fullname: event.target.value,
    });
  }

  handleEmailAddress(event){
    this.props.setStep1({
      ...this.props.step1,
      emailaddress: event.target.value,
    });    
  }

  handleUploadClick(){
    this.uploadButton.click();
  }


  async onPreview (file){
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };  

  //Upload Image
  async uploadImage(options){
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        this.setState({
          ...this.state,
          progress: percent
        });
        if (percent === 100) {
          setTimeout(() => this.setState({
            ...this.state,
            progress: 0
          }), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    fmData.append("image", file);
    try {
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/uploadimage.php",
        fmData,
        config
      );
      let warnmessage = "";
      let profilePic = "";
      let ext = "";
      if (res.data.result === "ok"){
        profilePic = res.data.fileid;
        ext = res.data.ext;
        warnmessage = "Resim yüklendi.";
        onSuccess("Ok");
        this.props.onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
        showWarningLayer();
      }else{
        ext = "";
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        const error = new Error("Some error");
        onError({ error });        
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
      }

      this.setState({
        ...this.state,
        progress: 0,
        profilePic: profilePic,
        ext: ext
      });
      
      this.props.setStep1({
        ...this.props.step1,
        profilePic: profilePic !== "" ? profilePic+"."+ext : "",
        ext:ext
      });
    } catch (err) {
      let warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
      const error = new Error("Some error");
      onError({ error });        
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
    }
  };  
  
  //Remove Image
  async removeImage(){
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("pid", this.state.profilePic);
    fmData.append("ext", this.state.ext);
    try {
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/removeimage.php",
        fmData,
        config
      );
      
      let warnmessage = "";
      if (res.data.result === "ok"){
        this.setState({
          ...this.state,
          progress: 0,
          profilePic: "",
          //defaultFileList:[],
          ext: ""
        });        
        this.props.setStep1({
          ...this.props.step1,
          profilePic: "",
          ext:""
        });
  
        warnmessage = "Resim kaldırıldı.";
        this.props.onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
        showWarningLayer();
      }else{
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
      }
    } catch (err) {
      let warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
    }    
  }



  componentDidMount(){
    this.props.setCurrent('step-1');
    window.scrollTo(0,0);
    
  }

  render(){
    return(
      <React.Fragment>
        <section className="sectionStepNew">
          <Header currentPage="step-1" />
          <div className="sectionWrapperNew">
            <div className="imageToolbarNew">
              <div className="imageToolbarLeft">
                  <h3 className="sectionTitleNew">Fotoğrafınız</h3>
                  <button className="buttonUploadNew">YÜKLE</button>
              </div>
              
              <ImgCrop shape="rect" quality="1" modalTitle="Resim Düzenle" modalCancel="İptal" modalOk="Tamam" rotate grid>
                <Upload
                  accept="image/*"
                  customRequest={this.uploadImage}
                  onChange={this.handleOnChange}
                  listType="picture-card"
                  defaultFileList={this.state.defaultFileList}
                  className="image-upload-grid"
                  onPreview={this.onPreview}
                  onRemove={this.removeImage}
                >
                  {
                    this.state.defaultFileList.length >= 1 ? null 
                    :
                    <React.Fragment>
                      <div className="uploadContentWrapper">
                        <svg xmlns="http://www.w3.org/2000/svg" className="iconFemale" viewBox="0 0 50 50">
                          <g data-name="1" id="_1">
                            <rect fill="none"/>
                            <path d="M30.933,32.528  c-0.026-0.287-0.045-0.748-0.06-1.226c4.345-0.445,7.393-1.487,7.393-2.701c-0.012-0.002-0.011-0.05-0.011-0.07  c-3.248-2.927,2.816-23.728-8.473-23.306c-0.709-0.6-1.95-1.133-3.73-1.133c-15.291,1.157-8.53,20.8-12.014,24.508  c-0.002,0.001-0.005,0.001-0.007,0.001c0,0.002,0.001,0.004,0.001,0.006c0,0.001-0.001,0.002-0.001,0.002s0.001,0,0.002,0.001  c0.014,1.189,2.959,2.212,7.178,2.668c-0.012,0.29-0.037,0.649-0.092,1.25C19.367,37.238,7.546,35.916,7,45h38  C44.455,35.916,32.685,37.238,30.933,32.528z" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                          </g>
                        </svg> 
                        <button className="buttonUploadHidden" ref={this.uploadButton}>Resim Yükle</button> 
                      </div>
                    </React.Fragment>
                  }
                  
                </Upload>
                {this.state.progress > 0 ? <Progress percent={this.state.progress} /> : null}
              </ImgCrop>

            </div>
            <div className="mainInfoWrapper">
              <div className="mainInfoItem">
                <h3 className="sectionTitleNew">Adınız / Soyadınız</h3>
                <div className={`inputWrapper ${this.props.step1.fullnameError === true ? "inputError" : ""}`}>
                  <input type="text" placeholder="Lütfen tam adınızı giriniz" className="edits-login" onChange={this.handleFullName} value={this.props.step1.fullname} />
                </div>
              </div>
              <div className="mainInfoItem">
                <h3 className="sectionTitleNew">Kayıt E-Posta Adresi</h3>
                <div className="inputWrapper">
                  <input type="text" placeholder="Lütfen e-posta adresi giriniz." className="edits-login" onChange={this.handleEmailAddress} value={this.props.step1.emailaddress} />
                </div>
              </div>

            </div>
          </div>          
          <Footer currentPage="step-1" parentPage="baslangic" />
        </section>
      </React.Fragment>




    );
  }
}

const mapStateToProps = state => {
  return {
    step1: state.definitions.step1,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep1: (step1) => dispatch({ type: DefinitionsActionTypes.SET_STEP1, step1: step1}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}




export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step1));