import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import './header.styles.scss';

class Header extends React.Component{

  constructor(props){
    super(props);
    this.state = {};
  }

  goTo = (event,url) => {
    event.preventDefault();
    return this.props.history.push('/'+url);    
  }

  componentDidMount(){
    console.log(this.props.currentPage);
  }  

  render(){
    return(
      <React.Fragment>
        <div className="sectionHeaderContentWrapper">
          
          {
            this.props.currentPage !== "baslangic" ?
            this.props.currentPage !== "success" &&
              <React.Fragment>
              <h2 className="sectionHeaderTitle">Katılımcı Bilgi Formu</h2>          
              <nav className="sectionNavbar">
                <div>
                  <Link to="/signup/step-1" className={`nav-links ${this.props.currentPage === "step-1" ? 'nav-active' : ''}`}>
                    1.ADIM
                  </Link>              
                </div>
                <div>
                  <Link to="/signup/step-2" className={`nav-links ${this.props.currentPage === "step-2" ? 'nav-active' : ''}`}>
                    2.ADIM
                  </Link> 
                </div>
                <div>
                  <Link to="/signup/step-3" className={`nav-links ${this.props.currentPage === "step-3" ? 'nav-active' : ''}`}>
                    3.ADIM
                  </Link> 
                </div>
                <div className={this.props.currentPage === 'step-4' ? 'nav-active' : ''}>
                  <Link to="/signup/step-4" className={`nav-links ${this.props.currentPage === "step-4" ? 'nav-active' : ''}`}>
                    4.ADIM
                  </Link> 
                </div>
                <div className={this.props.currentPage === 'step-5' ? 'nav-active' : ''}>
                  <Link to="/signup/step-5" className={`nav-links ${this.props.currentPage === "step-5" ? 'nav-active' : ''}`}>
                    5.ADIM
                  </Link>
                </div>
              </nav>
              </React.Fragment>
              : <h2 className="webSectionTitle">Merhaba!</h2>
          } 
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Header);