import React,{ useRef } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import GreenLine from '../../components/greenline/greenline.component';

import 'antd/dist/antd.css';
import './baslangic.page.styles.scss';

class Baslangic extends React.Component{

  constructor(props){
    super(props);
    this.state = {
    };
  }

  componentDidMount(){
    this.props.setCurrent('baslangic');
    window.scrollTo(0,0);
  }

  goTo = (event,type) => {
    event.preventDefault();
    this.props.setCurrent('step-1');
    return this.props.history.push('/signup/step-1');    
  }  


  render(){
    return(
      <React.Fragment>
        <div className="baslangicWrapperNew">
            <GreenLine />
            <div className="baslangicContentWrapper">
              <div className="baslangicContent">
                <p className="baslangicYazi">
                  Merhaba!
                </p>
                <p>
                  Sensiz Olmaz Sessiz Olmaz veri tabanına aşağıdaki adımları takip ederek kolayca kaydolabilirsiniz.
                </p>
                <p>
                <strong>Adım 1 :</strong> Adınızı ve e-mailinizi kaydettikten sonra profilinizde görünecek bir fotoğraf eklemeniz gerekecek.
                </p>
                <p>
                <strong>Adım 2 : </strong> Çalıştığınız Sektör listesinden çalışmalarınızı hangi sektör içerisinde yürüttüğünüzü belirleyebilirsiniz. Aynı sayfa içerisinde Uzmanlık Alanlarınız başlığı altında çalışma alanlarınıza dair bilgi verebilirsiniz. Çalışma alanlarınızı tanımlayan anahtar kelimeleri yazarken detay verebileceğinizi hatırlatmak isteriz. Burada gireceğiz anahtar kelimeler, veri tabanında aratıldığında size daha kolay ulaşılmasını sağlayacak.
                </p>
                <p>
                <strong>Adım 3 : </strong> Bu adımda, çalıştığınız kurumu belirtmeniz istenecek. Aynı zamanda "Bağımsız Çalışıyorum" seçeneği de mevcut. Sonrasında özgeçmişinizi bir dosya olarak yükleyebileceğiniz gibi varsa LinkedIn hesabınızın bağlantısını da kopyalayabilirsiniz. Belirtmek isterseniz yaşadığınız ülke ve şehri de bu adımda seçebilirsiniz.
                </p> 
                <p>
                <strong>Adım 4 : </strong> Medya ve/veya konferans deneyimlerinizi profilinizde görünmesini istediğiniz ilgili video ve yayınları da ekleyerek son adıma geçebilirsiniz.
                </p>  
                <p>
                <strong>Adım 5 : </strong> Bu adımda, ilgili kurum çalışanlarının sizin ile nasıl iletişime geçeceğini belirleyeceksiniz. Telefon ve e-posta adreslerinizin profilinizde görünmesi onayınıza sunulacak. Varsa sosyal medya hesaplarınızı profilinize ekleyebilirsiniz. Son olarak, Aydınlatma Metni ve Ön Bilgilendirme Formu'nu okumanızı özellikle rica ederiz. Dikkatlice okuyup onayladıktan sonra "Tamamla" butonuna tıklayıp profilinizi oluşturabilirsiniz.
                </p>           
                <p>
                  Hoş geldiniz!
                </p>  
                <button className="btnWeb btnFilterSearch btnBaslaNew" onClick={(event) => this.goTo(event)}>BAŞLA</button>
              </div>
            </div>
      </div>
      
    </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    step1: state.definitions.step1,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep1: (step1) => dispatch({ type: DefinitionsActionTypes.SET_STEP1, step1: step1}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}




export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Baslangic));