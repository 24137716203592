import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import './filter.styles.scss';

const Filter = () => {
  const xprops = useSelector(state => state);
  return(
    <React.Fragment>
      <div id="filterID" className="filterSectionWrapperID">
        <div className="filterSectionWrapper">
            <h4 className="filterSectionTitle">"{xprops.project.language.keywords.kadinUzmanlarVeriTabaniText}"</h4>
            <div className="filterGridWrapper">
                <Link to="/signup" className="middleRedBox">{xprops.project.language.keywords.uzmanlarArasinaKatilin}</Link>
                <Link to="/uzman-bul" className="middleRedBox">{xprops.project.language.keywords.uzmanArayin}</Link>
            </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Filter;