import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import RadioButton from '../../components/radiobutton/radiobutton.component';
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';

import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';

import { showWarningLayer } from '../../utils/utils';

import {sectors} from '../../utils/sectors';
import {experties} from '../../utils/experties';

import '../../App.css';
import './step2.styles.scss';

class Step2 extends React.Component{

  constructor(props){
    super(props);
    this.state = {
      topicName:'',
      topics: (this.props.step2.topics === null || this.props.step2.topics === undefined) ? [] : this.props.step2.topics,
      checkboxes: experties.reduce(
        (options, option) => ({
          ...options,
          [option]: (this.props.step2.experties !== undefined && this.props.step2.experties !== null && this.props.step2.experties.length > 0 && this.props.step2.experties.includes(option)) ? true : false
        }),
        {}
      ),
      sector: sectors.reduce(
        (options, option) => ({
          ...options,
          [option]: this.props.step2.sector !== undefined && this.props.step2.sector.includes(option) ? true : false
        }),
        {
        }
      )      

    };
    this.handleExperties = this.handleExperties.bind(this);
    this.handleTopicChange = this.handleTopicChange.bind(this);
    this.addToTopics = this.addToTopics.bind(this);
    this.removeItem = this.removeItem.bind(this);

    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.props.setStep2({
      ...this.props.step2,
      expertiesError: false,
      sectorError: false,
      topicsError: false,
      countriesError: false,
      regionsError: false
    });    
    
  }


  handleExperties = (event) => {
    this.props.setStep2({
      ...this.props.step2,
      expertiestext: event.target.value,
    });

    
  }  



  handleTopicChange(event){
    this.setState({
      ...this.state,
      topicName: event.target.value
    })
  }  


  handleCheckboxChange = changeEvent => {
    const { name } = changeEvent.target;
    this.setState(prevState => ({
      checkboxes: {
        ...prevState.checkboxes,
        [name]: !prevState.checkboxes[name]
      }
    }),()=> {
        let tmpArr = [];  
        Object.entries(this.state.checkboxes).filter((obj)=>{
          return obj[1] === true && tmpArr.push(obj[0])
        }
        );
        this.props.setStep2({
          ...this.props.step2,
          experties: tmpArr
        }); 
    });
  };
  handleSectorChange = changeEvent => {
    const { name } = changeEvent.target;

    this.setState({
      sector: sectors.reduce(
        (options, option) => ({
          ...options,
          [option]: option === name ? true : false
        }),
        {
        }
      )      

    });

    let tmpArr = [];
    tmpArr.push(name);
    this.props.setStep2({
      ...this.props.step2,
      sector: tmpArr
    }); 
    
    
  };

  addToTopics(){
    let newLink = this.state.topicName;
    if (newLink.length < 3){
      const warnmessage = "Lütfen konu başlığını kontrol edin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
      return;
    }

    const newArray = this.state.topics.slice();    
    newArray.push(newLink);

    this.setState({
      ...this.state,
      topics: newArray,
      topicName:''
    });

    this.props.setStep2({
      ...this.props.step2,
      topics:newArray
    })      
  }

  removeItem(item,index){
    const newList = [...this.state.topics];
    var tmpIndex = newList.indexOf(item);
    if (tmpIndex !== -1){
      newList.splice(index,1);
      this.setState({
        ...this.state,
        topics: newList
      })
      this.props.setStep2({
        ...this.props.step2,
        topics:newList,
      })      

    }
  }



  componentDidMount(){
    this.props.setCurrent('step-2');
    window.scrollTo(0,0);
  } 
  render(){
    return(
      <React.Fragment>
        <section className="sectionStepNew">
          <Header currentPage="step-2" />
          <div className="sectionWrapperNew">
            <div className="gridWrapperNew">
              <div>
                <h4 className={`sectionTitle`}>Çalıştığınız Sektör</h4>
                <div className="componentWrapper step2-layer1">
                    <div className="contentInfoWrapper">
                      {
                        sectors.map((item,index)=>{
                          return(
                          <RadioButton name={item} key={index} value={item} defaultChecked={this.state.sector[item]} checked={this.state.sector[item]} onClick={this.handleSectorChange} >{item}</RadioButton>
                          )
                        })
                      }


                    </div>
                </div>
              </div>
              <div>
                <h4 className={`sectionTitle`}>Uzmanlık Alanlarınız</h4>
                <div className="componentWrapper step2-layer1">
                    <textarea className="memos-new memos-new2" placeholder={`Lütfen uzmanlık alanlarınızı belirtiniz.`} onChange={this.handleExperties} value={this.props.step2.expertiestext} />
                    {/* <div className="contentInfoWrapper">
                    
                      { {
                        experties.map((item)=>{
                          return(
                          <CheckBox name={item} key={item} value={item} defaultChecked={this.state.checkboxes[item]} checked={this.state.checkboxes[item]} onChange={this.handleCheckboxChange} >{item}</CheckBox>
                          )
                        })
                      } }


                    </div> */}
                </div>
              </div>

            </div>
          </div>          
          <Footer currentPage="step-2" parentPage="step-1" />
        </section>
      </React.Fragment>


      
    );
  }
}

const mapStateToProps = state => {
  return {
    step2: state.definitions.step2,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep2: (step2) => dispatch({ type: DefinitionsActionTypes.SET_STEP2, step2: step2}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step2));