import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Select from "react-dropdown-select";
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import Header from '../../components/header/header.component';
import Footer from '../../components/footer/footer.component';
import CheckBox from '../../components/checkbox/checkbox.component';


import {countries} from '../../utils/countries';

import '../../App.css';
import './step3.styles.scss';
class Step3 extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      letterCount: (this.props.step3.shortcv !== undefined && this.props.step3.shortcv !== null && this.props.step3.shortcv.length > 0) ? this.props.step3.shortcv.length : 0 ,
      limit:200,
      freelance:(this.props.step3.isFree !== undefined && this.props.step3.isFree === true) ? true : false,
      region: (this.props.step3.city !== undefined && this.props.step3.city.length > 0 ) ? this.props.step3.city : ''

    };

    this.handleWebsite = this.handleWebsite.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handleLetters = this.handleLetters.bind(this);
    this.selectRegion = this.selectRegion.bind(this);
  }

  handleCompany(event){
    this.props.setStep3({
      ...this.props.step3,
      company: event.target.value,
    });
  }  

  handleWebsite(event){
    this.props.setStep3({
      ...this.props.step3,
      website: event.target.value,
    });
  }    


  componentDidMount(){
    this.props.setCurrent('step-3');
    window.scrollTo(0,0);
  } 

  handleLetters = (event) => {
    this.setState(()=>({
      ...this.state,
      letterCount: event.target.value.length
    }));

    this.props.setStep3({
      ...this.props.step3,
      shortcv: event.target.value,
    });

    
  }

  handleCheck = (target) => {
    let tmpCheck = false;
    if (target.checked === true){
      tmpCheck = true;
      this.props.setStep3({
        ...this.props.step3,
        company: "",
        isFree: true
      });
    }else{
      this.props.setStep3({
        ...this.props.step3,
        isFree: tmpCheck
      });

    }
    this.setState(()=>({
      ...this.state,
      freelance: tmpCheck
    }));

  }




  selectRegion (event) {
    this.props.setStep3({
      ...this.props.step3,
      city: event.target.value
    });        
    this.setState({...this.state, region: event.target.value });
  }  

  render(){
    return(
      <React.Fragment>
        <section className="sectionStepNew">
          <Header currentPage="step-3" />
          <div className="sectionWrapperNew">
            <div className="gridWrapperNew3">
              <div>
                <h4 className="sectionTitle">Çalıştığınız Kurum</h4>
                <input type="text" placeholder="Lütfen tam adınını giriniz" className="edits-login" disabled={this.state.freelance} onChange={this.handleCompany} value={this.props.step3.company} />
                <CheckBox value="bagimsiz" defaultChecked={this.state.freelance} checked={this.state.freelance}   onChange={(event) => this.handleCheck(event.target)}>Bağımsız Çalışıyorum</CheckBox>              
              </div>
              <div>
                <h4 className="sectionTitle">Kısa Özgeçmişiniz</h4>
                <textarea className="memos-new" placeholder={`Lütfen kısa özgeçmişinizi yazınız.`} onChange={this.handleLetters}>{this.props.step3.shortcv}</textarea>
                <span className="letterCount"><span className={this.state.letterCount > this.state.limit ? " tooManyLetters" : ""}>{this.state.letterCount}</span> / {this.state.limit}</span>
              </div>
              <div>
                <h4 className="sectionTitle">Yaşadığınız Ülke / Şehir</h4>
                <div className="componentWrapper country-city-step3">
                  <div className="contentInfoWrapper gorus-konular country-city">
                    <Select
                      values={ (this.props.step3.country === null || this.props.step3.country === undefined ) ? [] : this.props.step3.country }
                      labelField="name"
                      valueField="name"
                      searchable={true}
                      addPlaceholder="yazarak filtreleyin"
                      placeholder="Lütfen ülke seçiniz."
                      options={countries}
                      multi={false}
                      className="select-country-city"
                      onChange={(value) =>{
                          this.props.setStep3({
                            ...this.props.step3,
                            country: value
                          })
                        } 
                      }                    

                    />
                    
                    <input type="text" placeholder="Lütfen şehir giriniz" className="edits-login" onChange={this.selectRegion} value={this.props.step3.city} />
                  
                  </div>

                </div>
              </div>
            </div>
          </div>          
          <Footer currentPage="step-3" parentPage="step-2" />
        </section>
      </React.Fragment>
      
    );
  }
}

const mapStateToProps = state => {
  return {
    step3: state.definitions.step3,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setStep3: (step3) => dispatch({ type: DefinitionsActionTypes.SET_STEP3, step3: step3}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(Step3));