export const language = {
  "default":"TR",
  "TR":{
    "name":"Türkçe",
    "alias":"turkish",
    "short":"TR",
    "keywords": {
      "home":"ANASAYFA",
      "aboutUs":"HAKKIMIZDA",
      "findExpert":"UZMAN BUL",
      "ourEducations":"Eğitimlerimiz",
      "articles":"BLOG",
      "contactUs":"İLETİŞİM",
      "signIn":"GİRİŞ YAPIN",
      "signUp":"KATILIM",
      "keyword":"Anahtar Kelime",
      "sector":"Sektör",
      "profession":"Uzmanlık Alanı",
      "regionOfInterest":"İlgilendiği Bölge",
      "language":"Dil",
      "wantToJoinToOurExperts":"Uzmanlarımız arasına katılmak ister misiniz ?",
      "slogan1":"SEN OL Kİ MEDYANIN SESİ TAM OLSUN",
      "watchPromotionVideo":"Tanıtım videosunu izle",
      "wantToRecommendExpert":"Uzman önermek ister misiniz ?",
      "findWomanExpert":"Kadın Uzman Bulun",
      "pleaseSelectSector":"Lütfen bir sektör seçin",
      "pleaseSelectProfession":"Lütfen bir uzmanlık alanı seçin",
      "pleaseSelectRegion":"Lütfen bir bölge seçiniz",
      "pleaseSelectLanguage":"Lütfen bir dil seçiniz",
      "upcomingEducations":"Yaklaşan Eğitimler",
      "upcoming":"Yaklaşan",
      "educations":"Eğitimler",
      "featured":"Öne Çıkan",
      "lastJoined":"Son Katılan",
      "experts":"Uzmanlar",
      "lastJoinedExperts":"Son Katılan Uzmanlar",
      "getInTouch":"İletişime Geç",
      "recent":"Son Yapılan",
      "posts":"Paylaşımlar",
      "ourSponsors":"Sponsorlarımız",
      "ourSupporters":"Destekçilerimiz",
      "profile":"BİLGİLERİM",
      "messages":"Mesajlar",
      "egitimler":"EĞİTİMLER",
      "toplantilar":"TOPLANTILAR",
      "farkindalikCalismalari":"FARKINDALIK ÇALIŞMALARI",
      "mentorluk":"MENTORLUK",
      "signOut":"ÇIKIŞ",
      "welcome":"HOŞGELDİNİZ",
      "calismalar":"ÇALIŞMALAR",
      "kadinUzmanlarVeriTabaniText":"Kadın uzmanlar veri tabanına üye olmak ister misiniz?",
      "uzmanlarArasinaKatilin":"UZMANLAR ARASINA KATILIN",
      "uzmanArayin":"UZMAN ARAYIN",
      "kadinUzmanVeriTabani":"KADIN UZMAN VERİ TABANI",
      "medyaEgitimleri":"MEDYA EĞİTİMLERİ",
      "mentorlukCalismalari":"MENTORLUK ÇALIŞMALARI",
      "sessizOlmazBlog":"SESSİZ OLMAZ BLOG",
      "soruMesajlar":"Soru ve mesajlarınızı e-posta yoluyla bize iletebilirsiniz.",
      "sıkSorulanSorular":"Sık Sorulan Sorular",
      "herHakkiSaklidir":"Her hakkı saklıdır.",
      "kadinUzman":"KADIN UZMAN",
      "veriTabani":"VERİ TABANI",
      "calismalari":"ÇALIŞMALARI",
      "mentoring":"MENTORLUK",
      "farkindalik":"FARKINDALIK",
      "sosoCalismalari":"ÇALIŞMALAR",
      "mentorship":"MENTORLUK",
      "aramaKriterleri":"ARAMA KRİTERLERİ",
      "aradiginizBulunamadiTekrarDene":"Aradığınız kriterlerde uzman bulunamadı. Lütfen tekrar deneyiniz.",
      "iletisimBilgileri":"İletişim Bilgileri",
      "yukleniyor":"Yükleniyor",
      "uzmanBilgileri":"Uzman Bilgileri",
      "calistigiKurum":"Çalıştığı Kurum",
      "belirtilmemis": "Belirtilmemiş",
      "bagimsizCalisiyor":"Bağımsız Çalışıyor",
      "medyaDeneyimi":"Medya Deneyimi",
      "var":"Var",
      "yok":"Yok",
      "konferansDeneyimi":"Konferans Deneyimi",
      "kisaOzgecmis":"Kısa Özgeçmiş",
      "calistigiSektor":"Çalıştığı Sektör",
      "uzmanlikAlanlari":"Uzmanlık Alanları",
      "yayinVeReferanslar":"Yayın ve Referanslar",
      "sosyalMedyaHesaplari":"Sosyal Medya Hesapları",
      "projeEkibi":"PROJE EKİBİ",
      "toplantiResim1":"Lansman toplantısı katılımcıları Miranda Holt'u dinliyor.",
      "toplantiResim2":"Seda Demiralp Sensiz Olmaz Sessiz Olmaz’ın sağladığı medya eğitimi hakkında görüşlerini paylaşıyor.",
      "toplantiResim3":"Proje ekibinden Semin Gümüşel Güner ve Özge Genç Sensiz Olmaz Sessiz Olmaz projesiyle ilgili bilgi veriyor.",
      "toplantiResim4":"Medyada Kadın Temsili: Engelleri Aşmak, Çözümler Üretmek, 24 Kasım 2023, Istanbul",
      "egitimResim1":"Galatasaray Üniversitesi MEDİAR işbirliğiyle kadın uzmanlarla gerçekleştirilen medya eğitimi. / 16 Ocak 2023",
      "egitimResim2":"Bahçeşehir Üniversitesi’nden kadın uzmanlarla BAU EQUAL işbirliğiyle gerçekleştirilen medya eğitimi. / 7-8 Nisan 2023",
      "egitimResim3":"Sensiz Olmaz Sessiz Olmaz veri tabanı üyelerine yönelik online medya eğitimi",
      "epostaAdresi":"E-Posta Adresi",
      "sifre":"Şifre",
      "hesabinizYokmu":"Hesabınız Yok mu ?",
      "sifremiUnuttum":"Şifremi Unuttum",
      "kayitOl":"Kayıt Ol",
      "girisYap":"GİRİŞ YAP",
      "lutfenEpostaAdresiniGiriniz":"Lütfen e-posta adresinizi giriniz.",
      "sifreniziGiriniz":"Şifrenizi giriniz.",
      "basariylaCikisYapildi":"Başarıyla çıkış yapıldı.",
      "lutfenEpostaAdresiniKontrolEdin":"Lütfen e-posta adresinizi kontrol edin.",
      "lutfenSifreAlaniniKontrolEdin":"Lütfen şifre alanını kontrol edin.",
      "girisYapildi":"Giriş Yapıldı",
      "kullaniciAdiVeyaSifreKontrol":"Lütfen kullanıcı adı veya şifrenizi kontrol edin.",
      "turkce":"Türkçe",
      "english":"English"
      
      


      


      
      
    }
  },
  "EN":{
    "name":"English",
    "alias":"english",
    "short":"EN",
    "keywords": {
      "home":"HOMEPAGE",
      "aboutUs":"ABOUT US",
      "findExpert":"FIND AN EXPERT",
      "ourEducations":"OUR TRAININGS",
      "articles":"BLOG",
      "contactUs":"CONTACT",
      "signIn":"LOGIN",
      "signUp":"SIGNUP",
      "keyword":"Keyword",
      "sector":"Sector",
      "profession":"Profession",
      "regionOfInterest":"Region of Interest",
      "language":"Language",
      "wantToJoinToOurExperts":"Would you like to join our experts?",
      "slogan1":"Be the one so that the media's voice is full",
      "watchPromotionVideo":"Watch the promotional video",
      "wantToRecommendExpert":"Would you like to recommend an expert?",
      "findWomanExpert":"Find a Woman Expert",
      "pleaseSelectSector":"Please select a sector",
      "pleaseSelectProfession":"Please select a profession",
      "pleaseSelectRegion":"Please select a region",
      "pleaseSelectLanguage":"Please select a language",
      "upcomingEducations":"Upcoming Educations",
      "upcoming":"Upcoming",
      "educations":"Trainings",
      "featured":"Featured",
      "lastJoined":"Last Joined",
      "experts":"Experts",
      "lastJoinedExperts":"Last Joined Experts",
      "getInTouch":"Contact Us",
      "recent":"Recent",
      "posts":"Shares",
      "ourSponsors":"Our sponsors",
      "ourSupporters":"Our supporters",
      "profile":"PROFILE",
      "messages":"MESSAGES",
      "egitimler":"TRAININGS",
      "toplantilar":"MEETINGS",
      "farkindalikCalismalari":"AWARENESS RAISING",
      "mentorluk":"MENTORSHIP",
      "signOut":"SIGN OUT",
      "welcome":"WELCOME",
      "calismalar":"PROJECTS",
      "kadinUzmanlarVeriTabaniText":"Would you like to become a member of the database of female experts?",
      "uzmanlarArasinaKatilin":"JOIN THE EXPERTS",
      "uzmanArayin":"SEARCH FOR AN EXPERT",
      "kadinUzmanVeriTabani":"WOMAN EXPERT DATABASE",
      "medyaEgitimleri":"MEDIA TRAININGS",
      "mentorlukCalismalari":"MENTORING STUDIES",
      "sessizOlmazBlog":"NOT WITHOUT YOUR VOICE BLOG",
      "soruMesajlar":"You can send us your questions and messages via e-mail.",
      "sıkSorulanSorular":"Frequently Asked Questions",
      "herHakkiSaklidir":"All rights reserved.",
      "kadinUzman":"WOMAN EXPERT",
      "veriTabani":"DATABASE",
      "calismalari":"RAISING",
      "mentoring":"MENTORING",
      "farkindalik":"AWARENESS",
      "sosoCalismalari":"Not Without You Not Without Your Voice Initiatives",
      "mentorship":"MENTORSHIP",
      "aramaKriterleri":"SEARCH CRITERIA",
      "aradiginizBulunamadiTekrarDene":"No experts were found matching your search criteria. Please try again.",
      "iletisimBilgileri":"Contact information",
      "yukleniyor":"Loading",
      "uzmanBilgileri":"Expert Information",
      "calistigiKurum":"Institution she is working at",
      "belirtilmemis": "Unspecified",
      "bagimsizCalisiyor":"Working freelance",
      "medyaDeneyimi":"Media Experience",
      "var":"Available",
      "yok":"Not Available",
      "konferansDeneyimi":"Conference Experience",
      "kisaOzgecmis":"Short CV",
      "calistigiSektor":"Sector in which she works",
      "uzmanlikAlanlari":"Areas of expertise",
      "yayinVeReferanslar":"Publications and References",
      "sosyalMedyaHesaplari":"Social Media Accounts",
      "projeEkibi":"PROJECT TEAM",
      "toplantiResim1":"Launch meeting attendees listen to Miranda Holt.",
      "toplantiResim2":"Seda Demiralp shares her views on the media training provided by Sensiz Olmaz, Silent Olmaz.",
      "toplantiResim3":"Semin Gümüşel Güner and Özge Genç, members of the project team, give information about the project 'Without You Will Not Be Silent'.",            
      "toplantiResim4":"Representation of Women in the Media: Overcoming Barriers, Generating Solutions, November 24, 2023, Istanbul",            
      "egitimResim1":"Media training conducted with female experts in cooperation with Galatasaray University MEDİAR. / January 16, 2023",
      "egitimResim2":"Media training carried out in cooperation with female experts from Bahçeşehir University and BAU EQUAL. / 7-8 April 2023",
      "egitimResim3":"Online media training for Sensiz Olmaz Sessiz Olmaz database members",
      "epostaAdresi":"Email Address",
      "sifre":"Password",
      "hesabinizYokmu":"Don't have an account?",
      "sifremiUnuttum":"Forgot password",
      "kayıtOl":"Sign Up",
      "girisYap":"SIGN IN",
      "lutfenEpostaAdresiniGiriniz":"Please enter your email address.",
      "sifreniziGiriniz":"Enter your password.",
      "basariylaCikisYapildi":"Successfully signed out.",
      "lutfenEpostaAdresiniKontrolEdin":"Please check the email address.",
      "lutfenSifreAlaniniKontrolEdin":"Please check the password.",
      "girisYapildi":"Successfully signed in",
      "kullaniciAdiVeyaSifreKontrol":"Please check the username or password.",
      "turkce":"Türkçe",
      "english":"English"                  
    }
  }  
}