import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import 'antd/dist/antd.css';
import './baslangicmedya.page.styles.scss';

class BaslangicMedya extends React.Component{

  constructor(props){
    super(props);
    this.state = {
    };
  }

  componentDidMount(){
    this.props.setCurrent('baslangicmedya');
    window.scrollTo(0,0);
    
  }

  goTo = (event,type) => {
    event.preventDefault();
    this.props.setCurrent('kayit-formu');
    return this.props.history.push('/medyakayit/kayit-formu');    
  }  


  render(){
    return(
      <section className="mainSection">
        <div className="sectionWrapper">
          <div className="baslangicWrapper">
            <p className="baslangicYazi">
              Sensiz Olmaz Sessiz Olmaz veri tabanına aşağıdaki adımları takip ederek kolayca kaydolabilirsiniz.
            </p>
            <p>
              Adınız, soyadınız ve e-posta adresinizi kaydettikten sonra profilinizde görünecek bir fotoğraf eklemeniz gerekecek. Fotoğraf eklemek zorunlu değil, ancak eklemenizi öneririz. 
            </p>
            <p>
              Çalıştığınız kurumu, kurumun web sitesini ekleyerek belirtmeniz istenecek. Aynı zamanda "Bağımsız Çalışıyorum" seçeneği de mevcut. Sonrasında kurum veya kendiniz için kısa bir açıklama istenecektir.
            </p>
            <p>
              Son olarak, <strong>Aydınlatma Metni ve Ön Bilgilendirme Formu</strong>'nu okumanızı özellikle rica ederiz. Dikkatlice okuyup onayladıktan sonra "Tamamla" butonuna tıklayıp profilinizi oluşturabilirsiniz.
            </p>  
            <p>
              Profiliniz oluşturulduktan sonra, daha sonra düzenleme yapmanız için kullanıcı adı ve şifreniz belirttiğiniz e-posta adresine gönderilecek. 
            </p>             
            <p>Hoş geldiniz!</p>
              <button className="btnWeb btnFilterSearch btnBasla" onClick={(event) => this.goTo(event)}>BAŞLA</button>
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value })
  }
}




export default connect(mapStateToProps,mapDispatchToProps)(withRouter(BaslangicMedya));