import React from 'react';
import GreenLine from '../greenline/greenline.component';
import { useSelector } from "react-redux";
import './blogcontent.style.scss';

const BlogContent = () => {
  const xprops = useSelector(state => state);
 return (
  <div className="newSectionWrapper">
    <GreenLine classx="spacebottom"/>
    <div className="newContentLayerWrapper">
      <div className="blogSectionWrapper blogbg">
        <div className="articleItem">
          <img className="blogPageImg" src="/assets/images/sessiz-olmaz-blog.jpg" alt="SENSİZ OLMAZ SESSİZ OLMAZ BLOG"/> 
        </div>
        <div className="articleItem">
          <div className="articleContentTop">
            <div className="articleContentHeader">
              <img className="articleContentBlogImg" src="/assets/images/sessiz-olmaz-logo.png" alt="BLOG"/> 
              <h2 className="articleItemTitle">BLOG</h2>
            </div>

          </div>
          <p className="articleItemContent">
            {
              xprops.project.language.short === "TR" ?
                <React.Fragment>
                  Sessiz Olmaz blogunda çeşitli alanlarda makaleler,<br/> röportajlar ve podcastler paylaşılıyor.
                  <br/>
                  Bloga uzmanlık alanınızdan bir yazı ile katkıda bulunmak isterseniz, lütfen bizimle iletişime geçin.
                  <br/><br/>
                  <a href="https://www.sessizolmaz.org" className="linkToBlog" rel="noreferrer" target="_blank">Blog için tıklayın</a>
                </React.Fragment>
                :
                <React.Fragment>
                  On the Sessiz Olmaz Blog, articles, interviews, and podcasts covering various fields are shared. If you would like to contribute an article from your area of expertise, please contact us.
                <br/><br/>
                <a href="https://www.sessizolmaz.org" className="linkToBlog" rel="noreferrer" target="_blank">Click here for the blog</a>
                </React.Fragment>
            }
          </p> 
        </div>          
      </div>
    </div>
  </div>
 );
}

export default BlogContent;