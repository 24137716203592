import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../../redux/project/project.types';

import { BrowserRouter as Router, Switch, Route, Redirect,Link,useHistory  } from 'react-router-dom';
import './articles.styles.scss';

const Articles = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();

  const [articles, setArticles] = useState(false);
  const [latestBlogPosts, setLatestBlogPosts] = useState([]);
  const baseUrl = "https://www.sessizolmaz.org/";
  let history = useHistory();
  const goToArticle = (url) => {
    if (url === ""){
      return history.push('/blog');    
    }else{
      return history.push('/blog/'+url);    
    }
  }

  const goToUrl = (url) => {
    window.location = url;
  }
  


  useEffect(() => {
    setLatestBlogPosts(xprops.project.latestPosts);
  }, [])



  return(
    <React.Fragment>
      <div id="makalelerIDWrapper">
        <div className="articlesSectionWrapper">
          <div className="articleItem">
            <img className="blogContentLogoImg" src="/assets/images/sessiz-olmaz-logo.png" alt="BLOG"/> 
            <h2 className="articleItemTitle">BLOG</h2>
            <p className="articleItemContent">
              Sessiz Olmaz başlıklı blog sayfasında toplumsal cinsiyet eşitliği ana teması altında farklı alanlarda yazılar okuyucularla paylaşılıyor ve sosyal medya hesaplarında konuyla ilgili farkındalık yaratmak için içerikler üretiliyor. 
              <br/><br/>
              Blog sayfasına uzmanlık alanınızdan bir yazı ile katkıda bulunmak isterseniz bizimle temasa geçebilirsiniz. 
            </p> 
          </div>
          <div className="articleItem">
            <img className="blogContentBlogImg" src="/assets/images/sessiz-olmaz-blog.jpg" alt="SENSİZ OLMAZ SESSİZ OLMAZ BLOG"/> 
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Articles;
