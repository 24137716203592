import React from 'react';
import MenuItem from '../menuitem/menuitem.component';
import './menu.styles.scss';

const menuItems = [
  {
    pageTitle: "UZMAN BUL",
    pageLink: "uzman-bul",
    clickable: true,
    alias:"findExpert",
    subItems:[]
  },
  {
    pageTitle:"HAKKIMIZDA",
    pageLink:"hakkimizda",
    clickable: true,
    alias:"aboutUs",
    subItems:[]
  },
  {
    pageTitle:"BLOG",
    pageLink:"blog",
    clickable: true,
    alias:"articles",
    subItems:[]
  },
  {
    pageTitle:"ÇALIŞMALAR",
    pageLink:"blog",
    clickable: false,
    alias:"calismalar",
    subItems:[
      {
        pageTitle: "EĞİTİMLER",
        pageLink: "egitimler",
        clickable:true,
        alias:"egitimler",
        subItems:[]
      },
      {
        pageTitle: "TOPLANTILAR",
        pageLink: "toplantilar",
        clickable:true,
        alias:"toplantilar",
        subItems:[]
      },
      {
        pageTitle: "FARKINDALIK ÇALIŞMALARI",
        pageLink: "farkindalik-calismalari",
        clickable:true,
        alias:"farkindalikCalismalari",
        subItems:[]
      },
      {
        pageTitle: "MENTORLUK",
        pageLink: "mentorluk",
        clickable:true,
        alias:"mentorluk",
        subItems:[]
      }            
    ]
  },
  {
    pageTitle:"İLETİŞİM",
    pageLink:"iletisim",
    clickable: true,
    alias:"contactUs",
    subItems:[]
  }  
]; 
const Menu = (props) => {
  const menuList = menuItems.map((e, i) => 
    <MenuItem pageTitle={e.pageTitle} pageLink={e.pageLink} clickable={e.clickable} alias={e.alias} subItems={e.subItems}  active={props.activeMenu !== undefined ? true : false} key={`menuitem_${i}`} />
  )  
  
  return(
    <ul className="bottomMenu">
      {menuList}
    </ul>
  );
}

export default Menu;