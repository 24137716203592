import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import GreenLine from '../../components/greenline/greenline.component';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import './successmedya.styles.scss';

class MedyaSuccess extends React.Component{

  constructor(props){
    super(props);
    this.state = {
       fullname:""
    };
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      fullnameError: false
    });    
  }

  goToExpert = (event) => {
    event.preventDefault();
    if (this.props.experturl !== null){
      return window.location = this.props.experturl;    
    }else{
      return false;
    }
    
  }
  goToHome = (event) => {
    event.preventDefault();
    if (this.props.experturl !== null){
      return window.location = "/";    
    }else{
      return false;
    }
    
  }  

  componentDidMount(){
    window.scrollTo(0,0);
    this.props.setParent('home');
    this.props.setCurrent('medyasuccess');
    this.props.setKayitFormu([]); 
  }

  


  render(){
    return(
      <div className="baslangicWrapperNew">
        <GreenLine />
        <div className="sectionWrapper sectionWrapperSuccess">
          <p className="girisYaziSuccess">
            İşleminiz başarıyla gerçekleşmiştir.
            <span>Birkaç dakika içinde e-mail adresinize gönderilecek aktivasyon mesajındaki linke tıklayarak profilinizi doğrulayabilirsiniz.</span> 
            <span>Gün içinde tarafınıza ayrıca bir onay mesajı gönderilecek ve profiliniz aktif olacaktır.</span>
            <span className="buttonWrapperSuccess">
              <button className="btnWeb btnFilterSearch" type="button" onClick={(event) => this.goToExpert(event)}>Uzman sayfasına dön</button>            
              <button className="btnWeb btnFilterSearch" type="button" onClick={(event) => this.goToHome(event)}>Anasayfaya dön</button>            
            </span>
          </p>
          <img src='../../assets/images/success-result.png' alt="İşleminiz başarıyla gerçekleşmiştir. " />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    kayitformu: state.definitions.kayitformu,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage,
    parentPage: state.project.parentPage,
    experturl: state.login.experturl
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setKayitFormu: (kayitformu) => dispatch({ type: DefinitionsActionTypes.SET_KAYITFORMU, kayitformu: kayitformu}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value }),
    setParent: (value) => dispatch({ type: ProjectActionTypes.SET_PARENT, value: value})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(MedyaSuccess));