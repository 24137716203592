export const sectors = [
  "Sivil Toplum (STK, Düşünce Kuruluşları)",
  "Kamu Sektörü",
  "Uluslararası Organizasyon",
  "Özel Sektör",
  "Akademi (Üniversite)",
  "Medya / Gazeteci",
  "Serbest Meslek",
  "Girişimci",
  "Sağlık ve Tıp",
  "Sanat ve Kültür",
  "Hukuk / Avukat",
  "Siyasetçi / Milletvekili"
];

export const sectorsForSelect = [
  { "label":"Lütfen seçiniz","value":""},
  { "label":"Sivil Toplum (STK, Düşünce Kuruluşları)","value":"Sivil Toplum (STK, Düşünce Kuruluşları)"},
  { "label":"Kamu Sektörü","value":"Kamu Sektörü"},
  { "label":"Uluslararası Organizasyon","value":"Uluslararası Organizasyon"},
  { "label":"Özel Sektör","value":"Özel Sektör"},
  { "label":"Akademi (Üniversite)","value":"Akademi (Üniversite)"},
  { "label":"Medya / Gazeteci","value":"Medya / Gazeteci"},
  { "label":"Serbest Meslek","value":"Serbest Meslek"},
  { "label":"Girişimci","value":"Girişimci"},
  { "label":"Sağlık ve Tıp","value":"Sağlık ve Tıp"},
  { "label":"Sanat ve Kültür","value":"Sanat ve Kültür"},
  { "label":"Hukuk / Avukat","value":"Hukuk / Avukat"},
  { "label":"Siyasetçi / Milletvekili","value":"Siyasetçi / Milletvekili"}
]