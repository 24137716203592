import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import BlogContent from '../../components/blogcontent/blogcontent.component';
import FooterNew from '../../components/footernew/footernew.component';
import './blog.page.styles.scss';

const BlogPage = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  


  if(!mounted){
    setParent("home");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0, 0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <BlogContent />
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default BlogPage;