import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect,useRouteMatch } from 'react-router-dom';
import { ProjectActionTypes } from '../../redux/project/project.types';
import NavBar from '../../components/home/navbar/navbar.component';
import FooterNew from '../../components/footernew/footernew.component';
import Baslangic from '../../pages/baslangic/baslangic.page.component';
import Step1 from '../../pages/step1/step1.component';
import Step2 from '../../pages/step2/step2.component';
import Step3 from '../../pages/step3/step3.component';
import Step4 from '../../pages/step4/step4.component';
import Step5 from '../../pages/step5/step5.component';
import Success from '../../pages/success/success.component';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as actionCreators from '../../redux/login/login.actions';


const SignUp = () => {
  const [mounted, setMounted] = useState(false);
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  
  const closePopup = () => dispatch(actionCreators.setFormLoginStatus(false));

  let match = useRouteMatch();
  if(!mounted){
    setParent("signup");  
  }
    
  useEffect(() =>{
    window.scrollTo(0,0);
    setMounted(true);
    closePopup();
  },[]);  

  //const setCurrent = (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value });  
  
  
    
  
  return(
    <React.Fragment>
    <div className="homeWrapper">
      <NavBar menutype="show" />     
      <div className="newSectionWrapper">
        <div className="newContentWrapper">
          <Switch>
            <Route exact path={`${match.path}/baslangic`} component={Baslangic} />
            <Route path={`${match.path}/step-1`} component={Step1} />
            <Route path={`${match.path}/step-2`} component={Step2} />
            <Route path={`${match.path}/step-3`} component={Step3} />
            <Route path={`${match.path}/step-4`} component={Step4} />
            <Route path={`${match.path}/step-5`} component={Step5} />
            <Route path={`${match.path}/success`} component={Success} />
            <Route exact path="/signup">
              <Redirect to={`${match.path}/baslangic`} />
            </Route> 
          </Switch> 
        </div>
      </div>
    </div>
    <FooterNew/>    
    </React.Fragment>

  );
}


export default SignUp;