import React, { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import ReactPlayer from 'react-player';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './toplantilar.page.styles.scss';

const ToplantilarPage = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  if(!mounted){
    setParent("toplantilar");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">{xprops.project.language.keywords.toplantilar}</h1>
              <p className="subContentParagraph">
                {
                  xprops.project.language.short === "TR" ? 
                    <React.Fragment>
                      Sensiz Olmaz Sessiz Olmaz, kadın uzmanlar ile medya, sivil toplum ve kamu temsilcilerini bir araya getiren toplantılar düzenliyor. 
                    </React.Fragment>
                    :
                    <React.Fragment>
                      Sensiz Olmaz Sessiz Olmaz, organizes meetings that bring together women experts and media, civil society and public representatives.
                    </React.Fragment>
                }
              
              </p>
            </div>
            <div className="subContentSectionWrapper bgGrey spacerTop75">

              <h2 className="toplantiBaslik">
                {
                  xprops.project.language.short === "TR" ? 
                  <React.Fragment>
                    Sensiz Olmaz Sessiz Olmaz Tanıtım Toplantısı, 14 Haziran 2022, Istanbul
                  </React.Fragment>
                  :
                  <React.Fragment>
                    Sensiz Olmaz Sessiz Olmaz Introduction Meeting, June 14, 2022, Istanbul
                  </React.Fragment>
                }
                
              </h2>
              <p className="subContentParagraph">
                {
                  xprops.project.language.short === "TR" ? 
                  <React.Fragment>
                    Sensiz Olmaz Sessiz Olmaz projesinin tanıtıldığı toplantı 14 Haziran 2022’de gerçekleşti. Medya sektörü, akademi, iş dünyası ve sivil toplum kuruluşlarından bilinen yüzlerin katıldığı toplantıda BBC 50:50'den Miranda Holt benzer uluslararası bir uygulama olarak BBC 50:50’ye dair deneyimlerini de paylaştı. Toplantıda Sensiz Olmaz Sessiz Olmaz projesinin kısa tanıtımıyla beraber medya eğitimlerimizde yer alan eğitmen ve katılımcılar medya eğitimi konusundaki görüşlerini aktardılar.                
                  </React.Fragment>
                  :
                  <React.Fragment>
                    The meeting introducing the Sensiz Olmaz Sessiz Olmaz project took place on June 14, 2022. Known figures from the media sector, academia, business, and civil society organizations attended the event. Miranda Holt from BBC 50:50 shared her experiences with a similar international initiative, BBC 50:50. Alongside a brief introduction to the Sensiz Olmaz Sessiz Olmaz project, trainers and participants involved in our media training programs shared their views on media education during the meeting.
                  </React.Fragment>
                }
                
              </p>

              <div className="egitimGrid nomarginbottom">
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/toplantilar1.jpg" alt={xprops.project.language.keywords.toplantiResim1} />
                    <p className="egitimColText">{xprops.project.language.keywords.toplantiResim1}</p>
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/toplantilar2.jpg" alt={xprops.project.language.keywords.toplantiResim2} />
                    <p className="egitimColText">{xprops.project.language.keywords.toplantiResim2} </p>                    
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/toplantilar3.jpg" alt={xprops.project.language.keywords.toplantiResim3} />
                    <p className="egitimColText">{xprops.project.language.keywords.toplantiResim3}</p>                                        
                  </div>
              </div>
              <div className="videoGrid">
                  <div className="videoCol">
                    <div className="videoPlayerWrapper">                    
                      <ReactPlayer
                        url='https://www.youtube.com/watch?v=2WYKlXvNpt4'
                        className='react-player'
                        playing={false}
                        controls
                        width='100%'
                        height='100%'
                      />                              
                    </div>
                    <p className="videoColText">
                      {
                        xprops.project.language.short === "TR" ? 
                        <React.Fragment>
                          Sensiz Olmaz Sessiz Olmaz Tanıtım Toplantısı - 14 Haziran 2022
                        </React.Fragment>
                        :
                        <React.Fragment>
                          Sensiz Olmaz Sessiz Olmaz Introduction Meeting, June 14, 2022, Istanbul
                        </React.Fragment>
                      }
                      
                    </p>
                  </div>

              </div>

              <h2 className="toplantiBaslik">
                {
                  xprops.project.language.short === "TR" ? 
                  <React.Fragment>
                    Medyada Kadın Temsili: Engelleri Aşmak, Çözümler Üretmek, 24 Kasım 2023, Istanbul
                  </React.Fragment>
                  :
                  <React.Fragment>
                    Representation of Women in the Media: Overcoming Barriers, Generating Solutions, November 24, 2023, Istanbul
                  </React.Fragment>
                }                
                
              </h2>
              <p className="subContentParagraph">
                {
                  xprops.project.language.short === "TR" ? 
                  <React.Fragment>
                      Sensiz Olmaz Sessiz Olmaz, 24 Kasım 2023’te, Işık Üniversitsie’nin desteğiyle, “Medyada Kadın Temsili: Engelleri Aşmak, Çözümler Üretmek” adlı bir toplantı gerçekleştirdi. Medya, akademi ve sivil toplum temsilcileriyle bir araya gelinen bu toplantıda kadın uzmanların medyada görünür olmalarının önündeki engeller tartışıldı. Deneyimli gazeteciler Senem Toluay Ilgaz ve Zeynep Özyol’un medya ne ister sorusuna cevap verdiği; akademisyen Seda Demiralp’in ekran deneyimlerini paylaştığı ve araştırmacı Feyza Akınerdem’in medyada kadın uzman görünürlüğüne dair yaptığı araştırmayı sunduğu toplantıda davetlilerin katkılarıyla çözüm önerileri tartışıldı.
                  </React.Fragment>
                  :
                  <React.Fragment>
                    Sensiz Olmaz Sessiz Olmaz, with the support of Işık University, organized a meeting titled "Representation of Women in the Media: Overcoming Barriers, Generating Solutions" on November 24, 2023. During this meeting, attended by media, academia, and civil society representatives, barriers to the visibility of women experts in the media were discussed. Experienced journalists Senem Toluay Ilgaz and Zeynep Özyol addressed the question of what the media wants. Academician Seda Demiralp shared her screen experiences, and researcher Feyza Akınerdem presented her research on the visibility of women experts in the media. The meeting featured contributions from guests, leading to discussions on solution proposals.
                  </React.Fragment>
                }                       
                
              </p>

              <div className="egitimGrid nomarginbottom">
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/meet1.jpg" alt={xprops.project.language.keywords.toplantiResim4} />
                    <p className="egitimColText"></p>
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/meet2.jpg" alt={xprops.project.language.keywords.toplantiResim4} />
                    <p className="egitimColText"></p>                    
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/meet3.jpg" alt={xprops.project.language.keywords.toplantiResim4} />
                    <p className="egitimColText"></p>                                        
                  </div>
              </div>              
              <div className="videoGrid">
                  <div className="videoCol">
                    <div className="videoPlayerWrapper">
                      <ReactPlayer
                          url='https://www.youtube.com/watch?v=kmHHeV0MffA'
                          className='react-player-video'
                          playing={false}
                          controls
                          width='100%'
                          height='100%'
                        />   

                    </div>
                    <p className="videoColText">{xprops.project.language.keywords.toplantiResim4}</p>                    
                  </div>
              </div>




              <div className="egitimDetailWrapper toplantilarWrapper">
                  <h2 className="egitimTitle">{xprops.project.language.keywords.toplantilar}</h2>
                  <ul className="egitimUl">
                    <li className="egitimLi">Pilot Medya Eğitim (8 – 9 Mayıs 2021)</li>
                    <li className="egitimLi">Bilim Kadınları ve Dış Politikada Kadınlar ile Medya Eğitimi (15 – 16 Mayıs 2021)</li>
                    <li className="egitimLi">Diyarbakır İş Kadınları Derneği (DİKAD) ile Medya Eğitimi (19 – 20 Haziran 2021)</li>
                    <li className="egitimLi">Türk Girişim ve İş Dünyası Konfederasyonu ile Medya Eğitimi (25 – 26 Eylül 2021) </li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi I (23 – 24 Ekim 2021)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi II (27 – 28 Kasım 2021)</li>
                    <li className="egitimLi">Bilim Akademisi ile Medya Eğitimi (12 – 13 Şubat 2022)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi III (26 – 27 Mart 2022)</li>
                    <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi IV (23 – 24 Nisan 2022)</li>
                    <li className="egitimLi">Özyeğin Üniversitesi ile Medya Eğitimi (28 – 29 Mayıs 2022)</li>
                    <li className="egitimLi">Kadın Akademisyenlere Yönelik Medya Eğitimi (26 – 27 Kasım 2022)</li>
                    <li className="egitimLi">Galatasaray Üniversitesi ile Medya Eğitimi (16 – 17 Ocak 2023)</li>
                    <li className="egitimLi">Deprem Uzmanlarına Yönelik Medya Eğitimi (12 Şubat 2023) </li>
                    <li className="egitimLi">Bahçeşehir Üniversitesi ile Medya Eğitimi (7 -8 Nisan 2023)</li>
                    <li className="egitimLi">Seçim Uzmanlarına Yönelik Medya Eğitimi (29 – 30 Nisan 2023)</li>
                  </ul>
              </div>





            </div> 
            <br/><br/>     
          </div>
        </div>
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default ToplantilarPage;