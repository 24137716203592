import React from 'react';
import { Redirect } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { ProjectActionTypes } from '../../redux/project/project.types';
import { DefinitionsActionTypes } from '../../redux/definitions/definitions.types';
import PhoneInput from 'react-phone-number-input/input';
import ImgCrop from 'antd-img-crop';
import { Upload, Progress } from 'antd';
import { showWarningLayer } from '../../utils/utils';
import CheckBox from '../../components/checkbox/checkbox.component';
import axios from "axios";
import 'antd/dist/antd.css';
//import ImageUploader from 'react-images-upload';

//import uploadButton from '../../assets/img/upload.svg';
//import removeButton from '../../assets/img/delete.svg';

import './kayitformu.styles.scss';

class KayitFormu extends React.Component{

  constructor(props){
    super(props);
    this.state = {
       defaultFileList: (this.props.kayitformu.profilePic === "" || this.props.kayitformu.profilePic === undefined || this.props.kayitformu.profilePic === null) ? [] : [{uid: '-1',name:this.props.kayitformu.profilePic, status: 'done', url:`https://www.sensizolmazsessizolmaz.org/img/${this.props.kayitformu.profilePic}` }],
       progress:0,
       profilePic:"",
       ext:"",
       fullname:"",
       telefon:"",
       freelance:(this.props.kayitformu.freelance !== undefined && this.props.kayitformu.freelance === true) ? true : false,
    };
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleFullName = this.handleFullName.bind(this);
    this.handleEmailAddress = this.handleEmailAddress.bind(this);
    this.handleTelefon = this.handleTelefon.bind(this);
    this.handleCompany = this.handleCompany.bind(this);
    this.handleWebsite = this.handleWebsite.bind(this);
    this.handleKisaAciklama = this.handleKisaAciklama.bind(this);
    this.handleCheck = this.handleCheck.bind(this);    
    this.onPreview = this.onPreview.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.insertForm = this.insertForm.bind(this);
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      fullnameError: false
    });    
  }

  handleOnChange({ file, fileList, event }){
    this.setState({
      ...this.state,
      defaultFileList: fileList
    })
  };

  handleFullName(event){
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      fullname: event.target.value,
    });
  }

  handleEmailAddress(event){
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      emailaddress: event.target.value,
    });    
  }

  handleTelefon(value){
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      telefon: value
    });    
  }  
  
  handleCompany(event){
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      company: event.target.value,
    });
  }  

  handleWebsite(event){
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      website: event.target.value,
    });
  } 

  handleKisaAciklama(event){
    this.props.setKayitFormu({
      ...this.props.kayitformu,
      kisaAciklama: event.target.value,
    });
  }

  handleCheck = (target) => {
    let tmpCheck = false;
    if (target.checked === true){
      tmpCheck = true;
      this.props.setKayitFormu({
        ...this.props.kayitformu,
        company: "",
        freelance: true
      });
    }else{
      this.props.setKayitFormu({
        ...this.props.kayitformu,
        freelance: tmpCheck
      });

    }
    this.setState(()=>({
      ...this.state,
      freelance: tmpCheck
    }));

  }  
  

  async onPreview (file){
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };  

  //Upload Image
  async uploadImage(options){
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);
        this.setState({
          ...this.state,
          progress: percent
        });
        if (percent === 100) {
          setTimeout(() => this.setState({
            ...this.state,
            progress: 0
          }), 1000);
        }
        onProgress({ percent: (event.loaded / event.total) * 100 });
      }
    };
    fmData.append("image", file);
    try {
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/uploadimage.php",
        fmData,
        config
      );
      let warnmessage = "";
      let profilePic = "";
      let ext = "";
      if (res.data.result === "ok"){
        profilePic = res.data.fileid;
        ext = res.data.ext;
        warnmessage = "Resim yüklendi.";
        onSuccess("Ok");
        this.props.onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
        showWarningLayer();
      }else{
        ext = "";
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        const error = new Error("Some error");
        onError({ error });        
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
      }

      this.setState({
        ...this.state,
        progress: 0,
        profilePic: profilePic,
        ext: ext
      });
      
      this.props.setKayitFormu({
        ...this.props.kayitformu,
        profilePic: profilePic !== "" ? profilePic+"."+ext : "",
        ext:ext
      });
    } catch (err) {
      let warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
      const error = new Error("Some error");
      onError({ error });        
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
    }
  };  
  
  //Remove Image
  async removeImage(){
    const fmData = new FormData();
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    fmData.append("pid", this.state.profilePic);
    fmData.append("ext", this.state.ext);
    try {
      const res = await axios.post(
        "https://www.sensizolmazsessizolmaz.org/removeimage.php",
        fmData,
        config
      );
      
      let warnmessage = "";
      if (res.data.result === "ok"){
        this.setState({
          ...this.state,
          progress: 0,
          profilePic: "",
          //defaultFileList:[],
          ext: ""
        });        
        this.props.setKayitFormu({
          ...this.props.kayitformu,
          profilePic: "",
          ext:""
        });
  
        warnmessage = "Resim kaldırıldı.";
        this.props.onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
        showWarningLayer();
      }else{
        warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
      }
    } catch (err) {
      let warnmessage = "Bir sorun oluştu. Lütfen tekrar deneyiniz.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
    }    
  }

  insertForm = async () => {
    this.props.setLoading(true);
    const fmData = new FormData();
    let warnmessage = "";

   
    //profile picture
    if (this.props.kayitformu.profilePic === "" || this.props.kayitformu.profilePic === undefined || this.props.kayitformu.profilePic === null ){
      fmData.append("profilepic","");
      fmData.append("ext","");
    }else{
      fmData.append("profilepic",this.props.kayitformu.profilePic);
      fmData.append("ext",this.props.kayitformu.ext);
    }        

    //FullName
    if (this.props.kayitformu.fullname === "" || this.props.kayitformu.fullname === undefined || this.props.kayitformu.fullname === null ){
      warnmessage = "Lütfen ad / soyad alanını kontrol edin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      return;
    }else{
      fmData.append("fullname", this.props.kayitformu.fullname);
    }

    if (this.props.kayitformu.emailaddress === "" || this.props.kayitformu.emailaddress === undefined || this.props.kayitformu.emailaddress === null ){
      warnmessage = "Lütfen ad / soyad alanını kontrol edin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      return;
    }else{
      var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
      if (!pattern.test(this.props.kayitformu.emailaddress)) {
        warnmessage = "Lütfen geçerli bir e-posta adresi girin.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();
        return;            
      }else{
        fmData.append("emailaddress", this.props.kayitformu.emailaddress);
      }
    }

    //Telefon
    if (this.props.kayitformu.telefon === "" || this.props.kayitformu.telefon === undefined || this.props.kayitformu.telefon === null ){
      warnmessage = "Lütfen telefon alanını kontrol edin.";
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();      
      this.props.setLoading(false);
      return;
    }else{
      fmData.append("telefon", this.props.kayitformu.telefon);
    }    

    //Check for freelance & company
    if (this.props.kayitformu.freelance === undefined || this.props.kayitformu.freelance === null || this.props.kayitformu.freelance === false ){
      if (this.props.kayitformu.company === undefined || this.props.kayitformu.company === null || this.props.kayitformu.company === ""){
        warnmessage = "Lütfen çalıştığınız kurum adını giriniz.";
        this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
        showWarningLayer();   
        this.props.setLoading(false);   
        return;
      }else{
        fmData.append("freelance","0");  
        fmData.append("company",this.props.kayitformu.company);  
      }
    }else{
      fmData.append("freelance","1");  
      fmData.append("company","");  
    }

    //Website
    if (this.props.kayitformu.website === undefined || this.props.kayitformu.website === null || this.props.kayitformu.website === ""){
      fmData.append("website","");  
    }else{
      fmData.append("website",this.props.kayitformu.website);  
    }

    //kisa aciklama
    if (this.props.kayitformu.kisaAciklama === undefined || this.props.kayitformu.kisaAciklama === null || this.props.kayitformu.kisaAciklama === "" ){
      // warnmessage = "Lütfen kısa açıklama giriniz.";
      // this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      // showWarningLayer();   
      // this.props.setLoading(false);   
      // return;
      fmData.append("kisaAciklama","");
    }else{
      fmData.append("kisaAciklama",this.props.kayitformu.kisaAciklama);
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
      crossDomain: true
    }

    const res = await axios.post(
      "https://www.sensizolmazsessizolmaz.org/html/do_addmediaform.php",
      //"http://localhost/soso/new-participant-form",
      fmData,
      config
    );
    if (res.data.type === "done"){
      warnmessage = "Medya Formu başarıyla gönderildi.";
      this.props.onWarning({warningMessageType:'success', warningMessageText: warnmessage});      
      showWarningLayer();
      this.props.setLoading(false);
      setTimeout(()=>{
        this.props.setCurrent('medyasuccess'); 
        return this.props.history.push('/medyakayit/medyasuccess');         
      },2000);
    }else{
      if (res.data.message === "DP_CreateUser"){
        warnmessage = "Bu isim ile daha önceden kayıt oluşturulmuş. Lütfen tekrar deneyiniz.";
      }else if(res.data.message === "DP_InsertForm"){
        warnmessage = "Kayıt esnasında bir hata oluştu. Lütfen tekrar deneyiniz.";
      }else if(res.data.message === "DP_EmailAddress"){
        warnmessage = "Kayıt e-posta adresini kontrol ediniz.";
      }else{
        warnmessage = "Bir hata oluştu. Lütfen tekrar deneyiniz.";
      }
      this.props.onWarning({warningMessageType:'fail', warningMessageText: warnmessage});      
      showWarningLayer();
    }    
    this.props.setLoading(false);

  }    

  componentDidMount(){
    this.props.setLoading(false);    
    this.props.setCurrent('kayit-formu');
    window.scrollTo(0,0);
  }

  render(){
    return(
      <section className="mainSection">
        <div className="sectionWrapper">
          {
            // <p className="girisYazi">
            //   Lütfen profil bilgilerinizi adım adım doldurunuz.
            // </p>
          }
          <h4 className="sectionTitle">Fotoğrafınız</h4>
          <div className="imageToolbar">
            
          <ImgCrop shape="round" quality={1} modalTitle="Resim Düzenle" modalCancel="İptal" modalOk="Tamam" rotate grid>
            <Upload
              accept="image/*"
              customRequest={this.uploadImage}
              onChange={this.handleOnChange}
              listType="picture-card"
              defaultFileList={this.state.defaultFileList}
              className="image-upload-grid"
              onPreview={this.onPreview}
              onRemove={this.removeImage}
            >
              {
                this.state.defaultFileList.length >= 1 ? null 
                :
                <React.Fragment>
                  <div className="uploadContentWrapper">
                    {      
                      /*                    
                    <svg xmlns="http://www.w3.org/2000/svg" className="iconFemale" viewBox="0 0 50 50">
                      <g data-name="1" id="_1">
                        <rect fill="none"/>
                        <path d="M30.933,32.528  c-0.026-0.287-0.045-0.748-0.06-1.226c4.345-0.445,7.393-1.487,7.393-2.701c-0.012-0.002-0.011-0.05-0.011-0.07  c-3.248-2.927,2.816-23.728-8.473-23.306c-0.709-0.6-1.95-1.133-3.73-1.133c-15.291,1.157-8.53,20.8-12.014,24.508  c-0.002,0.001-0.005,0.001-0.007,0.001c0,0.002,0.001,0.004,0.001,0.006c0,0.001-0.001,0.002-0.001,0.002s0.001,0,0.002,0.001  c0.014,1.189,2.959,2.212,7.178,2.668c-0.012,0.29-0.037,0.649-0.092,1.25C19.367,37.238,7.546,35.916,7,45h38  C44.455,35.916,32.685,37.238,30.933,32.528z" fill="none" strokeLinecap="round" strokeMiterlimit="10" strokeWidth="2" />
                      </g>
                    </svg> 
                    */
                    }
                    <svg xmlns="http://www.w3.org/2000/svg" className="iconFemale" viewBox="0 0 32 32">
                      <g data-name="1" id="_1">
                        <path className="cls-1" d="M16,17a8,8,0,1,1,8-8A8,8,0,0,1,16,17ZM16,3a6,6,0,1,0,6,6A6,6,0,0,0,16,3Z" fill="#8e8e8e" strokeLinecap="round" strokeMiterlimit="0" strokeWidth="1"/>
                        <path className="cls-1" d="M23,31H9a5,5,0,0,1-5-5V22a1,1,0,0,1,.49-.86l5-3a1,1,0,0,1,1,1.72L6,22.57V26a3,3,0,0,0,3,3H23a3,3,0,0,0,3-3V22.57l-4.51-2.71a1,1,0,1,1,1-1.72l5,3A1,1,0,0,1,28,22v4A5,5,0,0,1,23,31Z" fill="#8e8e8e" strokeLinecap="round" strokeMiterlimit="0" strokeWidth="1"/>
                      </g>
                    </svg>                    
                    <button className="buttonUpload">Resim Yükle</button> 
                  </div>
                </React.Fragment>
              }
              
            </Upload>
            {this.state.progress > 0 ? <Progress percent={this.state.progress} /> : null}
          </ImgCrop>

          </div>
          <div className="mainInfoWrapper ">
            <div className="mainInfoWrapperKayitFormuRow">
              <div className="mainInfoItem">
                <h4 className="sectionTitle titleAdsoyad">Adınız / Soyadınız</h4>
                <div className={`inputWrapper ${this.props.kayitformu.fullnameError === true ? "inputError" : ""}`}>
                  <input type="text" placeholder="Lütfen tam adınızı giriniz" className="edits" onChange={this.handleFullName} value={this.props.kayitformu.fullname} />
                </div>
              </div>
              <div className="mainInfoItem">
                <h4 className="sectionTitle titleAdsoyad">Kayıt E-Posta Adresi</h4>
                <div className="inputWrapper">
                  <input type="text" placeholder="Lütfen e-posta adresi giriniz." className="edits" onChange={this.handleEmailAddress} value={this.props.kayitformu.emailaddress} />
                </div>
              </div>
            </div>
            <div className="mainInfoWrapperKayitFormuRow">
              <div className="mainInfoItem">
                <h4 className="sectionTitle titleAdsoyad">Telefon</h4>
                <div className={`inputWrapper `}>
                <PhoneInput
                  //ref={(input) => { this.phoneInput = input; }}
                  placeholder="Telefon numaranızı giriniz"
                  value={this.props.kayitformu.telefon}
                  onChange={this.handleTelefon}
                  className="edits"
                  defaultCountry="TR"
                />                
                  
                </div>
              </div>
              <div className="mainInfoItem">
                <h4 className="sectionTitle titleAdsoyad">Çalıştığınız Kurum</h4>
                <div className="inputWrapper">
                  <input type="text" placeholder="Lütfen tam adınını giriniz" className="edits" disabled={this.state.freelance} onChange={this.handleCompany} value={this.props.kayitformu.company} />
                  <CheckBox value="bagimsiz" defaultChecked={this.state.freelance} checked={this.state.freelance}   onChange={(event) => this.handleCheck(event.target)}>Bağımsız Çalışıyorum</CheckBox>              
                </div>
              </div>
            </div>  
            <div className="mainInfoWrapperKayitFormuRow">
              <div className="mainInfoItem">
                <h4 className="sectionTitle titleAdsoyad">Website</h4>
                <div className={`inputWrapper`}>
                  <input type="text" placeholder="Lütfen website adresi giriniz" className="edits" onChange={this.handleWebsite} value={this.props.kayitformu.website} />
                </div>
              </div>
              <div className="mainInfoItem">
                <h4 className="sectionTitle titleAdsoyad">Kısa Açıklama</h4>
                <div className="inputWrapper">
                  <textarea className="memos" placeholder={`Lütfen kısa açıklama yazınız.`} onChange={this.handleKisaAciklama} value={this.props.kayitformu.kisaAciklama}></textarea>
                </div>
              </div>
            </div>
            <div className="mainInfoWrapperKayitFormuRow">
              <button className="btnWeb btnFilterSearch btnBasla" onClick={this.insertForm}>Kayıt Ol</button>
            </div>                        
          </div>
        </div>
      </section>
    );
  }
}

const mapStateToProps = state => {
  return {
    kayitformu: state.definitions.kayitformu,
    warningMessage: state.project.warningMessageText,
    warningType: state.project.warningMessageType,
    currentPage: state.project.currentPage,
    showLoading: state.project.showLoading    
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onWarning: (payload) => dispatch({ type: ProjectActionTypes.SET_WARNING_MESSAGE, payload: payload}),
    setDefault: () => dispatch({ type: ProjectActionTypes.SET_DEFAULT}),
    setKayitFormu: (kayitformu) => dispatch({ type: DefinitionsActionTypes.SET_KAYITFORMU, kayitformu: kayitformu}),
    setCurrent: (value) => dispatch({type: ProjectActionTypes.SET_CURRENT, value: value }),
    setLoading: (showLoading) => dispatch({ type: ProjectActionTypes.SET_LOADING, showLoading: showLoading})
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(KayitFormu));