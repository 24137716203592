import React, { useState, useEffect } from 'react';
import {useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './mentorluk.page.styles.scss';

const MentorlukPage = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  const { people } = useParams();
  const history = useHistory();

  if(!mounted){
    setParent("mentorluk");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">{xprops.project.language.keywords.mentorship}</h1>
              <img className="imgMentorluk" src="/assets/images/mentorluk.jpg" alt={xprops.project.language.keywords.mentorship} />
              <p className="subContentParagraph2">
                {
                  xprops.project.language.short === "TR" ?
                  <React.Fragment>
                                  “Kendinizi yetersiz hissettiğiniz, ifade etmekte zorlandığınız veya ifade etmekten kaçındığınız durumlarla karşılaşıyor musunuz? Ast-üst ilişki yönetimi, ücret görüşmeleri, terfi konuşmalarını yönetmek veya iş-yaşam dengenizi olumlu yönde değiştirecek farklı taleplerinizi dile getirmek için desteğe mi ihtiyacınız var? İster özel sektörde, ister akademide, ister sivil toplumda olsun, iş ortamınız, çalışma arkadaşlarınız sizi strese sokuyor mu?<br/>
                    Eğer bu sorular hayatınızı ve kariyerinizi olumsuz etkiliyorsa, Sensiz Olmaz Sessiz Olmaz’ın veri tabanında yer alan üye kadın uzmanlar için sunduğu koçluk çalışmasından yararlanabilirsiniz.”<br/><br/>

                    Sensiz Olmaz Sessiz Olmaz platformu medya eğitimlerinin yanı sıra 2022’de kadınları iş hayatı kariyerlerinde güçlendirme amacı ile koçluk çalışması başlattı. Çalışma ile belirli sayıda kadın uzmana iş hayatlarında karşılaştıkları herhangi bir konuda destek verilmesi hedeflendi. Terfi görüşmesi yapma, hakkını arama, sunum yaparken rahat hissetme ve benzeri konularda çalışma yapmak isteyen 11 kadın uzman çalışmada yer aldı. Katılımcılar Sensiz Olmaz Sessiz Olmaz veri tabanına üye uzman kadınlar arasından seçildi.<br/> 
                    Katılımcılar kişiye ve kendi seçtikleri konuya özel birebir yürütülen bir koçluk çalışmasına dahil oldular. Koçluk çalışması Profesyonel Koç Rana Beri, MCC ile yapılan iki seanstan oluştu.<br/><br/> 
                  </React.Fragment>
                  :
                  <React.Fragment>
In addition to media training, the Sensiz Olmaz Sessiz Olmaz platform initiated a coaching program in 2022 with the aim of empowering women in their careers. The program aimed to provide support to a specific number of women experts in their professional lives, addressing any challenges they might encounter. Eleven women experts participated in the program, expressing interest in areas such as promotion interviews, advocating for their rights, feeling comfortable during presentations, and more.<br/><br/>

Participants were selected from expert women members of the Sensiz Olmaz Sessiz Olmaz database. They engaged in personalized one-on-one coaching sessions tailored to their individual needs and selected topics. The coaching program, consisting of two sessions, was conducted by Professional Coach Rana Beri, MCC*.<br/><br/>                    
                  </React.Fragment>
                }
              </p>
            </div>
          </div>
        </div>
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default MentorlukPage;