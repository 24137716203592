import React,{ useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { BrowserRouter as Router, Switch, Route, Redirect,useRouteMatch,useParams } from 'react-router-dom';

import NavBarSub from '../../components/navbarsub/navbarsub.component';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import './promotionfilm.styles.scss';

const PromotionFilm = () => {
  const [mounted, setMounted] = useState(false);
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  
  let match = useRouteMatch();
  if(!mounted){
    setParent("video");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  
   const url = "https://www.youtube.com/watch?v=vFoKS_cSbp4";

  return(
    <React.Fragment>
      <div className="videoSectionWrapper">
        <NavBarSub />
        <div className="videoWrapper">

          <div className='player-wrapper'>
            <ReactPlayer
              url={url}
              className='react-player'
              playing
              controls
              width='100%'
              height='100%'
            />          
          </div>        
          
        </div>
      </div>
    </React.Fragment>
  );
}

export default PromotionFilm;