import React, { useState, useEffect } from 'react';
import {useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import GreenLine from '../../components/greenline/greenline.component';
import FooterNew from '../../components/footernew/footernew.component';
import './egitimler.page.styles.scss';

const EgitimlerPage = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  

  const { people } = useParams();
  const history = useHistory();

  if(!mounted){
    setParent("egitimler");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0, 0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <div className="newSectionWrapper">
          <GreenLine classx="spacebottom"/>
          <div className="newContentLayerWrapper">
            <div className="subContentSectionWrapper">
              <h1 className="subContentTitle">{xprops.project.language.keywords.egitimler}</h1>
              <p className="subContentParagraph">
              Media training sessions are organized for women experts in their respective fields to establish more effective and efficient relationships with the media. Voluntary women experts are provided with theoretical and practical training sessions on "how the media works" and "ways to use the media effectively and efficiently" by experienced media professionals. Since May 2021, 138 women experts have participated in these training sessions.
              </p>
            </div>
            <div className="subContentSectionWrapper bgGrey spacerTop75">
              <div className="egitimGrid">
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/egitim1.jpg" alt={xprops.project.language.keywords.egitimResim1} />
                    <p className="egitimColText">{xprops.project.language.keywords.egitimResim1}</p>
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/egitim2.jpg" alt={xprops.project.language.keywords.egitimResim2} />
                    <p className="egitimColText">{xprops.project.language.keywords.egitimResim2}</p>                    
                  </div>
                  <div className="egitimCol">
                    <img className="egitimColImg" src="/assets/images/egitim3.jpg" alt={xprops.project.language.keywords.egitimResim3} />
                    <p className="egitimColText">{xprops.project.language.keywords.egitimResim3}</p>                                        
                  </div>
              </div>

              <div className="egitimDetailWrapper">
                  <h2 className="egitimTitle">{xprops.project.language.keywords.egitimler}</h2>
                  <ul className="egitimUl">
                    {
                      xprops.project.language.short === "TR" ?
                        <React.Fragment>
                          <li className="egitimLi">Pilot Medya Eğitim (8 – 9 Mayıs 2021)</li>
                          <li className="egitimLi">Bilim Kadınları ve Dış Politikada Kadınlar ile Medya Eğitimi (15 – 16 Mayıs 2021)</li>
                          <li className="egitimLi">Diyarbakır İş Kadınları Derneği (DİKAD) ile Medya Eğitimi (19 – 20 Haziran 2021)</li>
                          <li className="egitimLi">Türk Girişim ve İş Dünyası Konfederasyonu ile Medya Eğitimi (25 – 26 Eylül 2021) </li>
                          <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi I (23 – 24 Ekim 2021)</li>
                          <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi II (27 – 28 Kasım 2021)</li>
                          <li className="egitimLi">Bilim Akademisi ile Medya Eğitimi (12 – 13 Şubat 2022)</li>
                          <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi III (26 – 27 Mart 2022)</li>
                          <li className="egitimLi">Sensiz Olmaz Sessiz Olmaz Veri Tabanı Üyelerine Yönelik Medya Eğitimi IV (23 – 24 Nisan 2022)</li>
                          <li className="egitimLi">Özyeğin Üniversitesi ile Medya Eğitimi (28 – 29 Mayıs 2022)</li>
                          <li className="egitimLi">Kadın Akademisyenlere Yönelik Medya Eğitimi (26 – 27 Kasım 2022)</li>
                          <li className="egitimLi">Galatasaray Üniversitesi ile Medya Eğitimi (16 – 17 Ocak 2023)</li>
                          <li className="egitimLi">Deprem Uzmanlarına Yönelik Medya Eğitimi (12 Şubat 2023) </li>
                          <li className="egitimLi">Bahçeşehir Üniversitesi ile Medya Eğitimi (7 -8 Nisan 2023)</li>
                          <li className="egitimLi">Seçim Uzmanlarına Yönelik Medya Eğitimi (29 – 30 Nisan 2023)</li>
                          <li className="egitimLi">Kamudan ve akademiden kadın uzmanlarla eğitim (30 Eylül - 1 Ekim 2023) </li>
                          <li className="egitimLi">Üyelerle Eğitim (4-5 Kasım 2023)</li>
                        </React.Fragment>
                        :
                        <React.Fragment>
                          <li className="egitimLi">Pilot Media Training (8 – 9 May 2021)</li>
                          <li className="egitimLi">Media Training for Women in Science and Women in Foreign Policy (15 – 16 May 2021)</li>
                          <li className="egitimLi">Media Training with Diyarbakır Business Women's Association (DİKAD) (19 – 20 June 2021)</li>
                          <li className="egitimLi">Media Training with the Turkish Confederation of Businessmen and Industrialists (TÜRKONFED) (25 – 26 September 2021)</li>
                          <li className="egitimLi">Media Training I for Sensiz Olmaz Sessiz Olmaz Database Members (23 – 24 October 2021)</li>
                          <li className="egitimLi">Media Training II for Sensiz Olmaz Sessiz Olmaz Database Members (27 – 28 November 2021)</li>
                          <li className="egitimLi">Media Training with the Turkish Academy of Sciences (12 – 13 February 2022)</li>
                          <li className="egitimLi">Media Training III for Sensiz Olmaz Sessiz Olmaz Database Members (26 – 27 March 2022)</li>
                          <li className="egitimLi">Media Training IV for Sensiz Olmaz Sessiz Olmaz Database Members (23 – 24 April 2022)</li>
                          <li className="egitimLi">Media Training with Özyeğin University (28 – 29 May 2022)</li>
                          <li className="egitimLi">Media Training for Women Academicians (26 – 27 November 2022)</li>
                          <li className="egitimLi">Media Training with Galatasaray University (16 – 17 January 2023)</li>
                          <li className="egitimLi">Media Training for Earthquake Experts (12 February 2023)</li>
                          <li className="egitimLi">Media Training with Bahçeşehir University (7 - 8 April 2023)</li>
                          <li className="egitimLi">Media Training for Election Experts (29 – 30 April 2023)</li>
                          <li className="egitimLi">Training with Women Experts from Public and Academic Sectors (30 September - 1 October 2023)</li>
                          <li className="egitimLi">Training with Members (4-5 November 2023)</li>

                        </React.Fragment>
                    }
                  </ul>
              </div>
              

            </div>      
          </div>
        </div>
          
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default EgitimlerPage;