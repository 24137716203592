import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { project } from '../../utils/project';
import './footernew.styles.scss';



const FooterNew = (props) => {
  const xprops = useSelector(state => state);
  const currentYear = new Date().getFullYear();
  return(
    <React.Fragment>
      <div className="newFooter">
        <div className="footerSection">
          <div className="foot1"> 
            <img className="footerbar-logo" src={process.env.PUBLIC_URL + '/assets/images/bottom-logo.png'} alt={project.projectName} />
          </div>
          <div className="foot2"> 
            <p>{xprops.project.language.keywords.soruMesajlar}</p>
            <p>info@sensizolmazsessizolmaz.org</p>
          </div>
          <div className="foot3"> 
            <ul className="socialList">
              <li><span className="socialTitle">Twitter:</span> <a href="https://twitter.com/sensizolmazorg" target="_blank" rel="noreferrer">@sensizolmazorg</a></li>
              <li><span className="socialTitle">Instagram:</span> <a href="https://www.instagram.com/sensizolmazorg/" target="_blank" rel="noreferrer">sensizolmazorg</a></li>
              <li><span className="socialTitle">LinkedIn:</span> <a href="https://www.linkedin.com/company/sensizolmazorg" target="_blank" rel="noreferrer">sensizolmazorg</a></li>
              <li><span className="socialTitle">Facebook:</span> <a href="https://tr-tr.facebook.com/sensizolmazsessizolmaz/" target="_blank" rel="noreferrer">Sensiz Olmaz Sessiz Olmaz</a></li>
              <li><span className="socialTitle">Spotify:</span> <a href="https://open.spotify.com/show/3lololIRYftidaMjF2dzUu?si=fc724220f47f4100" target="_blank" rel="noreferrer" >Sensiz Olmaz Sessiz Olmaz Podcast</a></li>
            </ul>
          </div>
          <div className="foot4"> 
            <div className="faqWrapper">
              <Link to="/sss"><h5>{xprops.project.language.keywords.sıkSorulanSorular}</h5></Link>
            </div>
            <div className="copyrightWrapper">
              <span>Sensiz Olmaz Sessiz Olmaz</span>
              <span>©{currentYear} SensizOlmazSessizOlmaz.org. {xprops.project.language.keywords.herHakkiSaklidir}</span>
            </div>
          </div>
        </div>        
      </div>
    </React.Fragment>
  )
};


export default FooterNew;

