import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ProjectActionTypes } from '../../redux/project/project.types';
import Header from '../../components/home/header/header.component';
import Slider from '../../components/home/slider/slider.component';
import Filter from '../../components/home/filter/filter.component';
import Articles from '../../components/home/articles/articles.component';
import AboutLayer from '../../components/home/aboutlayer/aboutlayer.component';
import FooterNew from '../../components/footernew/footernew.component';
import './home.page.styles.scss';

const Home = () => {
  const xprops = useSelector(state => state);
  const dispatch = useDispatch();
  const [mounted, setMounted] = useState(false);
  const setParent = (value) => dispatch({type: ProjectActionTypes.SET_PARENT, value: value });  


  if(!mounted){
    setParent("home");  
  }

  useEffect(() =>{
    setMounted(true);
    window.scrollTo(0,0);
  },[]);  

    return(
      <React.Fragment>
        <div className="homeWrapper">
          <Header />
          <Slider />
          <Filter />
          <AboutLayer />
          <Articles />        
        </div>
        <FooterNew />
      </React.Fragment>
    );
};

export default Home;